import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Avast Internet Security",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/AVAST/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_Avast.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_Avast.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Avast' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/AVAST/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/AVAST/' crumbLabel="Avast" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-inside-your-antivirus-protection-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-inside-your-antivirus-protection-software",
        "aria-label": "set an exception rule inside your antivirus protection software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an Exception rule inside your Antivirus Protection Software`}</h2>
    <p>{`In some cases, it can be that the virus scanner "Avast" is blocking the video stream of the camera. Actually it blocks the ActiveX and Quicktime Plugin from receiving the cameras video stream. In this case you will need to set an exception in Avast so it does not block the camera anymore.`}</p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the program Avast by clicking the Avast symbol on the right side of the windows task bar. Another way is to open the start menu and to choose the program directly. Once opened please click Settings on the upper right side.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/6aca1/Avast_Internet_Security_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHJN4ksTKf/xAAbEAACAwADAAAAAAAAAAAAAAAAAQIDERITIf/aAAgBAQABBQKVckcPGsHa2dg56f/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABcRAAMBAAAAAAAAAAAAAAAAAAIQESH/2gAIAQIBAT8BGzV//8QAGRABAAMBAQAAAAAAAAAAAAAAAAEhMRFB/9oACAEBAAY/AouLdp4xjH//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhcYH/2gAIAQEAAT8hCT82ro5bMEBPMuJGusHm/9oADAMBAAIAAwAAABDHD//EABcRAQADAAAAAAAAAAAAAAAAAAABEWH/2gAIAQMBAT8QjVv/xAAVEQEBAAAAAAAAAAAAAAAAAAAQcf/aAAgBAgEBPxC6f//EABoQAAIDAQEAAAAAAAAAAAAAAAERACFBMZH/2gAIAQEAAT8QQWIAkyApIgUhR8h2wdAvs0WfXkQF5b0QmbpcT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/e4706/Avast_Internet_Security_01_EN.avif 230w", "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/d1af7/Avast_Internet_Security_01_EN.avif 460w", "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/69c29/Avast_Internet_Security_01_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/a0b58/Avast_Internet_Security_01_EN.webp 230w", "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/bc10c/Avast_Internet_Security_01_EN.webp 460w", "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/c1dc5/Avast_Internet_Security_01_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/e83b4/Avast_Internet_Security_01_EN.jpg 230w", "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/e41a8/Avast_Internet_Security_01_EN.jpg 460w", "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/6aca1/Avast_Internet_Security_01_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a8f71ee7c1443c0f3c639ae1a32ee288/6aca1/Avast_Internet_Security_01_EN.jpg",
              "alt": "Avast Internet Security",
              "title": "Avast Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the program configuration.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: In the window Basic settings please select the setting Troubleshoot on the left side.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/09da63c809eb1f3f621b644bc3f3fb23/6aca1/Avast_Internet_Security_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAIDAQQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAenK6RQ2AyEAn//EABgQAAMBAQAAAAAAAAAAAAAAAAEDEwAg/9oACAEBAAEFAoqIinQVh1//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAgMAAAAAAAAAAAAAAAAAAjKRASAz/9oACAEBAAY/AoYILRzWt//EABsQAAIDAAMAAAAAAAAAAAAAAAABECExEWGR/9oACAEBAAE/IdDxmiLLGX3CHgm+D//aAAwDAQACAAMAAAAQgMg//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHRABAQABBAMAAAAAAAAAAAAAAQARITFBUWGB8f/aAAgBAQABPxBIcrxBLfNWQgKb6QcqDjn3bZYSdTtTf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09da63c809eb1f3f621b644bc3f3fb23/e4706/Avast_Internet_Security_02_EN.avif 230w", "/en/static/09da63c809eb1f3f621b644bc3f3fb23/d1af7/Avast_Internet_Security_02_EN.avif 460w", "/en/static/09da63c809eb1f3f621b644bc3f3fb23/69c29/Avast_Internet_Security_02_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/09da63c809eb1f3f621b644bc3f3fb23/a0b58/Avast_Internet_Security_02_EN.webp 230w", "/en/static/09da63c809eb1f3f621b644bc3f3fb23/bc10c/Avast_Internet_Security_02_EN.webp 460w", "/en/static/09da63c809eb1f3f621b644bc3f3fb23/c1dc5/Avast_Internet_Security_02_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09da63c809eb1f3f621b644bc3f3fb23/e83b4/Avast_Internet_Security_02_EN.jpg 230w", "/en/static/09da63c809eb1f3f621b644bc3f3fb23/e41a8/Avast_Internet_Security_02_EN.jpg 460w", "/en/static/09da63c809eb1f3f621b644bc3f3fb23/6aca1/Avast_Internet_Security_02_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/09da63c809eb1f3f621b644bc3f3fb23/6aca1/Avast_Internet_Security_02_EN.jpg",
              "alt": "Avast Internet Security",
              "title": "Avast Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: In the Troubleshooting menu please go to the section Web and select the field Ignored addresses.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2275fb448dab9ee863c84db989079d1d/6aca1/Avast_Internet_Security_03_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6dtpMG4sCoT/xAAaEAACAgMAAAAAAAAAAAAAAAAAAwETEBEg/9oACAEBAAEFAqVSQlRQrG+f/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBMyAykf/aAAgBAQAGPwLSCtSteZ//xAAcEAACAgIDAAAAAAAAAAAAAAAAAREhMUFRkdH/2gAIAQEAAT8haF9Y34DERXBBbM7EPAnJ/9oADAMBAAIAAwAAABCgxz//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMVFhkcH/2gAIAQEAAT8QEtjoEEr2MM/FMDbtCwHkjoiRgvcwScRhbP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2275fb448dab9ee863c84db989079d1d/e4706/Avast_Internet_Security_03_EN.avif 230w", "/en/static/2275fb448dab9ee863c84db989079d1d/d1af7/Avast_Internet_Security_03_EN.avif 460w", "/en/static/2275fb448dab9ee863c84db989079d1d/69c29/Avast_Internet_Security_03_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2275fb448dab9ee863c84db989079d1d/a0b58/Avast_Internet_Security_03_EN.webp 230w", "/en/static/2275fb448dab9ee863c84db989079d1d/bc10c/Avast_Internet_Security_03_EN.webp 460w", "/en/static/2275fb448dab9ee863c84db989079d1d/c1dc5/Avast_Internet_Security_03_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2275fb448dab9ee863c84db989079d1d/e83b4/Avast_Internet_Security_03_EN.jpg 230w", "/en/static/2275fb448dab9ee863c84db989079d1d/e41a8/Avast_Internet_Security_03_EN.jpg 460w", "/en/static/2275fb448dab9ee863c84db989079d1d/6aca1/Avast_Internet_Security_03_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2275fb448dab9ee863c84db989079d1d/6aca1/Avast_Internet_Security_03_EN.jpg",
              "alt": "Avast Internet Security",
              "title": "Avast Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Please fill in the IP address of your camera. If you have more than one IP cameras please type in each IP address separated by a comma (,). Now please confirm the settings by clicking OK.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3307f8ec25baa2d3a995335fae740ebc/6aca1/Avast_Internet_Security_04_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6dthi3FoCAn/xAAaEAABBQEAAAAAAAAAAAAAAAADAAEQEzER/9oACAEBAAEFAqRJgiVAo7L42f/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAAICAwAAAAAAAAAAAAAAAAACATMgMpH/2gAIAQEABj8C0grUrXmf/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERITFRQZHR/9oACAEBAAE/IZFfWN+AxEVoguTNyIq7Whpsf//aAAwDAQACAAMAAAAQs8dD/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAEx/9oACAEDAQE/ECaf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARMSFBYZHB/9oACAEBAAE/EEtseBBK9xhn4pwNuoWgy9EcBIwDsWERJGJVs//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3307f8ec25baa2d3a995335fae740ebc/e4706/Avast_Internet_Security_04_EN.avif 230w", "/en/static/3307f8ec25baa2d3a995335fae740ebc/d1af7/Avast_Internet_Security_04_EN.avif 460w", "/en/static/3307f8ec25baa2d3a995335fae740ebc/69c29/Avast_Internet_Security_04_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3307f8ec25baa2d3a995335fae740ebc/a0b58/Avast_Internet_Security_04_EN.webp 230w", "/en/static/3307f8ec25baa2d3a995335fae740ebc/bc10c/Avast_Internet_Security_04_EN.webp 460w", "/en/static/3307f8ec25baa2d3a995335fae740ebc/c1dc5/Avast_Internet_Security_04_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3307f8ec25baa2d3a995335fae740ebc/e83b4/Avast_Internet_Security_04_EN.jpg 230w", "/en/static/3307f8ec25baa2d3a995335fae740ebc/e41a8/Avast_Internet_Security_04_EN.jpg 460w", "/en/static/3307f8ec25baa2d3a995335fae740ebc/6aca1/Avast_Internet_Security_04_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3307f8ec25baa2d3a995335fae740ebc/6aca1/Avast_Internet_Security_04_EN.jpg",
              "alt": "Avast Internet Security",
              "title": "Avast Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Finally, please reload the web interface of your IP camera. This can be done by either clicking the refresh button next to the address bar of your web browser or you can close your web browser and re-type the IP address of your camera in the browsers address bar.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      